import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import navBack from '../../assets/img/icons/nav-back.svg'
import navVerified from '../../assets/img/icons/nav-verified.svg'

import navAuthenticator from '../../assets/img/icons/nav-authenticator.svg'
import navHelpCenter from '../../assets/img/icons/nav-help-center.svg'
import navNotificationBell from '../../assets/img/icons/nav-notification-bell.svg'
import navDots from '../../assets/img/icons/nav-dots.svg'
import selectArrow from '../../assets/img/icons/select-arrow.svg'


import actionArrowDown from '../../assets/img/icons/action-arrow-down.svg'
import actionArrowTop from '../../assets/img/icons/action-arrow-top.svg'
import actionArrowSwap from '../../assets/img/icons/action-arrow-swap.svg'
import actionArrowOffchain from '../../assets/img/icons/action-arrow-offchain.svg'
import actionAutoconvert from '../../assets/img/icons/action-autoconvert.svg'

import svgplus from '../../assets/img/icons/plus.svg';
import Loader from "../../components/Loader";
import useUserData from "../../hooks/useUserData";
import useTransactions from "../../hooks/useTransactions";
import useAssets from "../../hooks/useAssets";
import Menu from '../../components/Menu'
import usePriceCurrencies from "../../hooks/usePriceCurrencies";
import {getAccount, getPriceCurrencies, updatePriceCurrencies} from "../../utils/userApi";
import useTranslation from "../../hooks/useTranslation";
import LogoSvg from "../../assets/img/logo.svg";

function Start() {
    // const navigate = useNavigate();

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();

    // useEffect(()=>{
    //     checkUser()
    // }, [])


    const checkUser = async () => {

        const {error, data} = await getAccount(null, webApp?.initData);

        console.log(error, 'errorerror')
        console.log(data, 'data checkUser')
        if (data?.auth2fa ) {
            window.location.href = 'settings-2fa?type=auth'
        } else {
            // window.location.href = '/'
            navigate('/')
        }

        if (error) {

            console.log(window.location, 'window.location')

            if (error?.kind == "AUTH_OTP_REQUIRED_ERR" ) {
                // navigate('/settings-2fa?type=auth')
                window.location.href = 'settings-2fa?type=auth'
            }
            return ;
        }

        console.log(data, 'data------')

        // handleLanguageChange(data?.language)
        //
        // setTimeout(()=>{
        //     setLoader(false)
        // }, 1000)
    };


    return (
        <div className={'loader-block'} style={{flexDirection:'column', justifyContent: 'space-between', paddingBottom:30}}>
            <img
                className="loader-block-image"
                width="86"
                height="86"
                src={LogoSvg}
                alt="token icon"
            />

            <div>
                <h1 className={'welcome_text'}>{translation?.welcome}</h1>
                <p className={'welcome_desc'} style={{textAlign:'center'}}>{translation?.start1_text}</p>
            </div>
            <div className={'btn'} style={{width: '90%'}}
                 onClick={()=>{
                     checkUser()
                 }}
            >
                {translation?.start}
            </div>
        </div>
    );
}

export default Start;
